import React from 'react';
import { Typography, Box, Link } from '@material-ui/core';
import Layout from '../../../components/layout';

function CheckoutSuccess() {
  return (
    <Layout>
      <Box mt={10}>
        <Box my={5}>
        <Typography variant='body1' gutterBottom align='center'>
            Thank you for registering to attend GMIS Connect – Trieste, where we will explore the theme of "Powering Sustainable Manufacturing: The Rise of Green Hydrogen" on Tuesday, 14 November 2023 at the Palace of the Autonomous Region of Friuli-Venezia Giulia. We look forward to welcoming you!
          </Typography>
        </Box>
      </Box>
    </Layout>
  );
}

export default CheckoutSuccess;
